import React, { useEffect, useState } from "react";
import { Link } from "gatsby";
import {
  MenuItems,
  GlobalOptions,
  MenuItem,
} from "../shared/interfaces/graphql.interface";
import styled from "styled-components";
import {
  RespContainer,
  AppButton,
  AppButtonLink,
  AppButtonAnchor,
} from "../styles/common";
import { Colors, Devices } from "../styles/variables";
import { FontelloIcon } from "../styles/CommonComponents";
import { CSSTransitionGroup } from "react-transition-group";
import FocusTrap from "focus-trap-react";
import { useWindowSize } from "./CustomHooks/useWindowSize";
import { cleanURL } from "./helpers/helpers";
import CalendlyButton from "./ReUsables/CalendlyButton";

interface HeaderDataProps {
  headerMenuItems: MenuItems;
  globalOptions: GlobalOptions;
  path: string;
}

const Header: React.FC<HeaderDataProps> = ({
  headerMenuItems,
  globalOptions,
  path,
}) => {
  const [toggleMenu, setToggleMenu] = useState(false);
  const [subDropdown, setSubDropdown] = useState(false);
  const [headerShadow, setHeaderShadow] = React.useState(false);
  const { width }: { width?: string } = useWindowSize();
  const isMobile = () => +width < 1200;

  const bannerHeadersList = [];
  const isPageWithBanner = () => isMobile() && bannerHeadersList.includes(path);

  const handleScroll = () => {
    let scrollTop = window.scrollY;
    if (scrollTop > 150) {
      setHeaderShadow(true);
    } else {
      setHeaderShadow(false);
    }
  };

  useEffect(() => {
    if (typeof document !== "undefined") {
      const DisableScroll = (val) => {
        document.querySelector("body").style.overflow = val;
        document.querySelector("html").style.overflow = val;
      };
  
      DisableScroll(toggleMenu ? "hidden" : "initial");
  
      handleScroll();
  
      window.addEventListener("scroll", handleScroll);
  
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }
  }, [toggleMenu]);

  return (
    <HeaderWrapper shadow={headerShadow}>
      <RespContainer>
        <ComponentWrapper>
          <HeaderChild>
            <HeaderChildInnerWrapper>
              <LogoWrapper>
                <Link to={"/"} aria-label={"go to home page"}>
                  <Logo
                    shadow={headerShadow}
                    src={
                      globalOptions &&
                      globalOptions[
                        isPageWithBanner() ? "logo_white_mobile" : "logo"
                      ]?.source_url
                    }
                    alt={
                      (globalOptions &&
                        globalOptions[
                          isPageWithBanner() ? "logo_white_mobile" : "logo"
                        ]?.alt_text) ||
                      "logo-alt"
                    }
                  />
                </Link>
              </LogoWrapper>
              <MenuItemsWrapper>
                <MenuUnorderList>
                  {headerMenuItems?.map((item) => (
                    <MenuItemWrapper key={`menu-${item.object_id}`}>
                      <MenuItemWrapperInside
                        onMouseEnter={() =>
                          item.wordpress_children && setSubDropdown(true)
                        }
                        onMouseLeave={() =>
                          item.wordpress_children && setSubDropdown(false)
                        }
                      >
                        <MenuListItem to={`${cleanURL(item.url)}`}>
                          {item.title}
                        </MenuListItem>
                        {item.wordpress_children && (
                          <SubDropdownWrapper>
                            {/* <SubDropdownLink
                            onClick={() => setSubDropdown(!subDropdown)}
                            > */}
                            <SubDropdownIconButton
                              aria-expanded={subDropdown}
                              aria-haspopup
                              onClick={() => setSubDropdown(!subDropdown)}
                              aria-label={`${
                                subDropdown ? "Open" : "Close"
                              } Dropdown`}
                            >
                              <FontelloIcon
                                icon={`${subDropdown ? "up" : "down"}-open`}
                              />
                            </SubDropdownIconButton>
                            {/* </SubDropdownLink> */}
                            {/* {subDropdown && ( */}
                            <SubDropdownMenu
                              style={subDropdown ? {} : { display: "none" }}
                            >
                              <SubDropdownTriangleWrapper>
                                <SubDropdownTriangle />
                              </SubDropdownTriangleWrapper>
                              <SubDropdownHeader />
                              <SubDropdownUnorderList>
                                {item.wordpress_children.map((sub) => (
                                  <SubDropdownListItem
                                    key={`sdmenu-${sub.object_id}`}
                                  >
                                    {/* <SubDropdownItemClickableArea> */}
                                    <SubdropdownItemLink
                                      to={cleanURL(sub.url)}
                                      onClick={() => setToggleMenu(false)}
                                    >
                                      {sub.title}
                                    </SubdropdownItemLink>
                                    {/* </SubDropdownItemClickableArea> */}
                                  </SubDropdownListItem>
                                ))}
                              </SubDropdownUnorderList>
                            </SubDropdownMenu>
                            {/* )} */}
                          </SubDropdownWrapper>
                        )}
                      </MenuItemWrapperInside>
                    </MenuItemWrapper>
                  ))}
                </MenuUnorderList>

                <MobileMenu>
                  <HamburgerMenuSlider
                    transitionName="hamburger"
                    transitionEnterTimeout={500}
                    transitionLeaveTimeout={300}
                  >
                    <OpenMenuIconButton
                      title="Open main navigation"
                      aria-expanded={toggleMenu}
                      onClick={() => setToggleMenu(true)}
                      aria-haspopup
                    >
                      <FontelloIcon
                        icon={"menu"}
                        style={
                          isPageWithBanner() ? {} : { color: Colors.sharpBlue }
                        }
                      />
                    </OpenMenuIconButton>
                    {toggleMenu && (
                      <FocusTrap>
                        <HamburgerMenu>
                          <HamburgerHeader>
                            <HamburgerLogoWrapper>
                              <Link
                                to={"/"}
                                onClick={() => setToggleMenu(false)}
                              >
                                <Logo src={globalOptions?.logo.source_url} />
                              </Link>
                            </HamburgerLogoWrapper>
                            <CloseMenuIconButton
                              title="Close main navigation"
                              onClick={() => setToggleMenu(false)}
                            >
                              <FontelloIcon icon={"cancel"} />
                            </CloseMenuIconButton>
                          </HamburgerHeader>

                          <HamburgerBody>
                            <HamburgerMenuUnorderList>
                              {headerMenuItems?.map((item) => (
                                <HamburgerMenuListItem
                                  key={`hmenu-${item.object_id}`}
                                >
                                  <MenuInnerComponent
                                    item={item}
                                    setToggleMenu={setToggleMenu}
                                  />
                                </HamburgerMenuListItem>
                              ))}
                            </HamburgerMenuUnorderList>
                          </HamburgerBody>

                          <HamburgerFooter>
                            <CallButton
                              href={`tel:${globalOptions?.company_information.phone}`}
                              reversedTheme
                            >
                              Give Us a Call:{" "}
                              <Phone>
                                {globalOptions?.company_information.phone}
                              </Phone>
                            </CallButton>
                            <ConsultationButton
                              to={`/contact`}
                              onClick={() => setToggleMenu(false)}
                            >
                             FREE CONSULT
                            </ConsultationButton>
                            {/* {typeof document !== "undefined" &&( <CalendlyButton className="calendly-button mobile"/>)} */}
                          </HamburgerFooter>
                        </HamburgerMenu>
                      </FocusTrap>
                    )}
                  </HamburgerMenuSlider>
                </MobileMenu>
              </MenuItemsWrapper>
            </HeaderChildInnerWrapper>

            <DesktopConsultationButton to={`/contact`}>
              FREE CONSULT
            </DesktopConsultationButton>
            {/* {typeof document !== "undefined" &&( <CalendlyButton className="calendly-button desktop"/>)} */}
          </HeaderChild>
        </ComponentWrapper>
      </RespContainer>
    </HeaderWrapper>
  );
};

export default Header;

interface MenuInnerComponentProps {
  item: MenuItem;
  setToggleMenu: React.Dispatch<React.SetStateAction<boolean>>;
}
const MenuInnerComponent = ({
  item,
  setToggleMenu,
}: MenuInnerComponentProps) => {
  const [dropdown, setDropdown] = useState(false);
  return (
    <>
      <HamburgerLIAndIconWrapper>
        <HamburgerMenuLink
          to={cleanURL(item.url)}
          onClick={() => setToggleMenu(false)}
        >
          {item.title}
        </HamburgerMenuLink>
        <ItemClickableArea
          onClick={() => {
            item.wordpress_children && setDropdown(!dropdown);
          }}
        >
          {item.wordpress_children && (
            <ToggleSubMenuIconButton
              title={`${dropdown ? "Open" : "Close"} Submenu`}
              aria-expanded={dropdown}
              aria-haspopup
            >
              <FontelloIcon icon={`${dropdown ? "up" : "down"}-open`} />
            </ToggleSubMenuIconButton>
          )}
        </ItemClickableArea>
      </HamburgerLIAndIconWrapper>
      {item?.wordpress_children && (
        <HamburgerSubmenuUnorderedList
          style={dropdown ? {} : { display: "none" }}
        >
          {item.wordpress_children.map((sub) => (
            <HamburgerSubmenuListItem key={`hsmenu-${sub.object_id}`}>
              <SubItemClickableArea>
                <HamburgerSubmenuLink
                  to={cleanURL(sub.url)}
                  onClick={() => setToggleMenu(false)}
                >
                  {sub.title}
                </HamburgerSubmenuLink>
              </SubItemClickableArea>
            </HamburgerSubmenuListItem>
          ))}
        </HamburgerSubmenuUnorderedList>
      )}
    </>
  );
};

const HeaderWrapper = styled("div")<{ shadow?: boolean }>`
  position: fixed;
  top: 0;
  width: 100%;
  background-color: #fff;
  z-index: 2;
  padding: ${(props) => (props.shadow ? "0.5rem 0" : "1rem 0")};
  box-shadow: ${(props) =>
    props.shadow ? "0 .5rem 1rem rgba(0,0,0,.15)!important" : "none"};
`;

const ComponentWrapper = styled("header")`
  @media all and (min-width: ${Devices.tabletLaptopBreakpoint}) {
    padding: 0;
  }

  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
`;

const LogoWrapper = styled("div")`
  /* >= 1200px; */
  @media all and (min-width: ${Devices.tabletLaptopBreakpoint}) {
    padding-right: 2.9rem;
  }

  /* >= 1440px; */
  @media all and (min-width: ${Devices.laptopMacBreakpoint}) {
    padding-right: 5.5rem;
  }
`;

const Logo = styled("img")<{ shadow?: boolean }>`
  @media all and (min-width: ${Devices.tabletLaptopBreakpoint}) {
    height: ${(props) => (props.shadow ? "60px" : "80px")};

    filter: unset;
  }
  height: ${(props) => (props.shadow ? "40px" : "50px")};
  transition: height 0.2s;
  /* filter: brightness(0) invert(1); */
`;

const MenuItemsWrapper = styled("nav")`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

const MenuUnorderList = styled("ul")`
  @media all and (min-width: ${Devices.tabletLaptopBreakpoint}) {
    display: flex;
  }
  display: none;
  list-style-type: none;
  padding: 0;
  margin: 0;
`;

const MenuItemWrapper = styled("li")`
  padding-right: 3rem;
  display: flex;
  align-items: center;

  /* >= 1200px; */
  @media all and (min-width: ${Devices.tabletLaptopBreakpoint}) {
    padding-right: 2.5rem;
  }

  /* >= 1440px; */
  @media all and (min-width: ${Devices.laptopMacBreakpoint}) {
    padding-right: 3rem;
  }
`;

const MenuItemWrapperInside = styled("div")`
  display: flex;
  align-items: center;
  position: relative;

  &:hover {
    /* cursor: pointer; */

    & a,
    div > button {
      color: ${Colors.sharpBlue};
      text-decoration: none;
    }
  }
`;

const MenuListItem = styled(Link)`
  text-decoration: none;
  font-weight: 600;
  color: ${Colors.ezBlack};
  white-space: nowrap;
  border-bottom: 1px solid transparent;

  /* &:hover, */
  &:focus {
    outline: none;
    color: ${Colors.sharpBlue};
    border-bottom: 1px solid ${Colors.sharpBlue};

    /* + div > a > button > span {
      color: ${Colors.sharpBlue};
    } */
  }
`;

const MobileMenu = styled("div")`
  @media all and (min-width: ${Devices.tabletLaptopBreakpoint}) {
    display: none;
  }
  > span {
    color: ${Colors.ezBlue};
    font-size: 24px !important;
  }
`;

const HeaderChild = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0;
  @media all and (min-width: ${Devices.tabletLaptopBreakpoint}) {
    /* justify-content: initial; */
    padding: 0;
  }
`;

const HeaderChildInnerWrapper = styled("div")`
  display: flex;
  align-items: center;

  width: 100%;
  justify-content: space-between;

  @media all and (min-width: ${Devices.tabletLaptopBreakpoint}) {
    width: 79%;
    justify-content: initial;
  }

  @media all and (min-width: ${Devices.laptopMacBreakpoint}) {
    width: 82%;
  }

  @media all and (min-width: ${Devices.laptopBreakpoint}) {
    width: 85%;
  }
`;

const OpenMenuIconButton = styled("button")`
  padding: 0.5rem;
  margin: 0;
  border: none;
  background: transparent;
  border-radius: 8px;
  height: 40px;
  width: 40px;

  &:hover,
  &:focus {
    background: ${Colors.ezGrayHover};
    outline: none;
  }

  color: ${Colors.white};
`;

const CloseMenuIconButton = styled(OpenMenuIconButton)`
  color: ${Colors.white};

  &:hover,
  &:focus {
    background: ${Colors.ezGrayHover};
    outline: none;
  }
`;

const HamburgerMenuSlider = styled(CSSTransitionGroup)`
  .hamburger-enter {
    transform: translate(-100%);
  }
  .hamburger-enter.hamburger-enter-active {
    transform: translate(0%);
    transition: transform 250ms ease-in-out;
  }
  .hamburger-leave {
    transform: translate(0%);
  }
  .hamburger-leave.hamburger-leave-active {
    transform: translate(-100%);
    transition: transform 250ms ease-in-out;
  }
`;

const HamburgerMenu = styled("div")`
  transition: width 2s;

  background-color: ${Colors.dodgeBlue};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  color: ${Colors.white};
  height: 100vh;
  z-index: 2;
  display: flex;
  flex-direction: column;
  overflow: auto;
`;

const HamburgerLogoWrapper = styled("div")`
  filter: brightness(0) invert(1);
`;

const HamburgerHeader = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.25rem;
  margin-top: 3.5rem;
`;

const HamburgerBody = styled("div")``;
const HamburgerMenuUnorderList = styled("ul")`
  list-style-type: none;
  padding: 3.25rem 2.5rem;
  margin: 0;
`;

const HamburgerMenuListItem = styled("li")`
  padding-bottom: 1.875rem;
`;

const HamburgerMenuLink = styled(Link)`
  font-size: 1.25rem;
  text-decoration: none;
  font-weight: 600;
  color: ${Colors.white};
  border-bottom: 1px solid transparent;
  text-transform: uppercase;
  white-space: nowrap;

  &:hover,
  &:focus {
    outline: none;
    border-bottom-color: ${Colors.white};
    color: ${Colors.white};
    text-decoration: none;
  }
`;

const ToggleSubMenuIconButton = styled(CloseMenuIconButton)``;

const HamburgerSubmenuUnorderedList = styled("ul")`
  list-style-type: none;
  padding: 1.25rem 1.875rem 0;
  margin: 0;
`;
const HamburgerSubmenuListItem = styled("li")`
  :not(:last-child) {
    padding-bottom: 1rem;
  }
`;
const HamburgerSubmenuLink = styled(Link)`
  font-size: 1rem;
  text-decoration: none;
  font-weight: 400;
  color: ${Colors.white};
  border-bottom: 1px solid transparent;

  &:hover,
  &:focus {
    outline: none;
    border-bottom-color: ${Colors.white};
  }
`;

const HamburgerFooter = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 1.25rem 1.25rem;
`;

const ConsultationButton = styled(AppButtonLink)`
  font-size: 1rem;
  text-align: center;
  text-decoration: none;
  width: 100%;
`;

const Phone = styled("span")`
  color: ${Colors.solidGray};
`;

const CallButton = styled(AppButtonAnchor)`
  background-color: ${Colors.white};
  color: ${Colors.sharpBlue};
  font-size: 1rem;
  margin-bottom: 1.25rem;
  text-align: center;
  text-decoration: none;
  width: 100%;

  &:hover,
  &:focus {
    span {
      color: ${Colors.white};
    }
  }
`;

const ItemClickableArea = styled("div")`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-end;
`;

const SubItemClickableArea = styled("div")`
  display: flex;
  align-items: center;
  width: 100%;
`;

const HamburgerLIAndIconWrapper = styled("div")`
  display: flex;
  align-items: center;
`;

const DesktopConsultationButton = styled(AppButtonLink)`
  font-size: 1rem;
  text-align: center;
  text-decoration: none;

  display: none;
  @media all and (min-width: ${Devices.tabletLaptopBreakpoint}) {
    display: initial;
  }

  border: 2px solid ${Colors.ezBlue};
  padding: 10px 30px;
`;

const SubDropdownWrapper = styled("div")`
  /* position: relative; */
`;

const SubDropdownLink = styled("a")`
  /* &:hover,
  &:focus {
    cursor: pointer;
  } */
`;

const SubDropdownMenu = styled("div")`
  width: 240px;
  position: absolute;
  top: 1.5rem;
  left: 50%;
  transform: translateX(-50%);
  background: ${Colors.white};
  border-radius: 0 0 11px 11px;
  /* box-shadow: -2px 28px 82px rgba(82, 114, 139, 0.3); */
`;

const SubDropdownTriangleWrapper = styled("div")`
  height: calc(1.375rem);
  background: transparent;
`;

const SubDropdownTriangle = styled("div")`
  position: absolute;
  left: 50%;
  transform: translate(-50%, 11px);
  width: 0;
  height: 0;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-bottom: 12px solid ${Colors.sharpBlue};
`;

const SubDropdownHeader = styled("div")`
  border-top: 6px solid ${Colors.sharpBlue};
  border-radius: 11px 11px 0 0;
`;

const SubDropdownIconButton = styled(OpenMenuIconButton)`
  padding: 0;
  margin: 0;
  height: auto;
  width: auto;
  color: ${Colors.ezBlack};

  &:hover,
  &:focus {
    background: transparent;
    color: ${Colors.sharpBlue};
    /* cursor: pointer; */
  }
`;

const SubDropdownUnorderList = styled("ul")`
  list-style-type: none;
  margin: 0;
  padding: 24px 30px;
  box-shadow: -2px 28px 82px rgba(82, 114, 139, 0.3);
`;

const SubDropdownListItem = styled("li")`
  /* &:hover,
  &:focus {
    outline: none;
    a {
      color: ${Colors.sharpBlue} !important;
    }
  } */
`;
// const SubDropdownItemClickableArea = styled("div")``;
const SubdropdownItemLink = styled(Link)`
  font-weight: 600;
  font-size: 14px;
  color: ${Colors.ezBlack} !important;
  line-height: 44px;
  padding-bottom: 32px;
  text-decoration: none;
  padding: 0;

  &:hover,
  &:focus {
    outline: none;
    color: ${Colors.sharpBlue} !important;
  }
`;
