import { navigate } from "gatsby";

function encode(data: Object) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

export const handleNetlifySubmit = (
  ref: React.RefObject<HTMLFormElement>,
  fields: Object,
  dest: string = "/thank-you"
) => {
  fetch("/", {
    method: "POST",
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    body: encode({
      "form-name": ref.current.props.name,
      ...fields,
    }),
  })
    .then(() => navigate(dest))
    .catch((error) => alert(error));
};

export const cleanURL: (url: string) => string = (url) => {
  let protocolFreeURL = url.substr(url.indexOf("//")).slice(2);
  let hostnameFreeURL = protocolFreeURL.substr(protocolFreeURL.indexOf("/"));
  let isHomePage = hostnameFreeURL === "/home/";
  let result = isHomePage ? "/" : hostnameFreeURL;
  return result;
};

interface ExtendedWindow extends Window {
  dataLayer?: any;
}

/**
 * @param eventName is a string that will
 * set as a value of window.dataLayer.event
 */
export const pushEventIntoDataLayer = (eventName: string) => {
  if (!(window as ExtendedWindow)?.dataLayer)
    (window as ExtendedWindow).dataLayer = [];
  (window as ExtendedWindow).dataLayer.push({ event: eventName });
};
