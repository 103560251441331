import React, { ReactNode } from "react";
import Header from "./Header";
import Footer from "./Footer";
import {
  GlobalOptions,
  MenuItems,
} from "../shared/interfaces/graphql.interface";
import "./layout-styled";
import "../fonts/Poppins/fonts.css";
import "../fonts/css/fontello.css";
import { createGlobalStyle } from "styled-components";
import { Helmet } from "react-helmet";
import "bootstrap/dist/css/bootstrap.min.css";
import "../fonts/later_icons/css/fontello.css";

import "../styles/WordPressStyles/styles.css";

// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css";
import "swiper/components/navigation/navigation.min.css";

import { GatsbySeo } from "gatsby-plugin-next-seo";

const GlobalStyle = createGlobalStyle`
  body { margin: 0; }
`;

interface PageDataProps {
  pageContext: { data: any };
  children: ReactNode;
}

type DefaultSeoDataType = {
  title: string;
  description: string;
  language: string;
  nofollow: boolean;
  no_index: boolean;
  canonical: string;
  openGraph: any;
  twitter: any;
};

export const Layout: React.FC<PageDataProps> = ({ children, pageContext }) => {
  let headerMenuItems: MenuItems;
  let footerMenuItems: MenuItems;
  let globalOptions: GlobalOptions;
  let path = "";
  let seo: any = {};

  if (pageContext?.data) {
    let { data } = pageContext;
    seo = data.acf?.seo;
    headerMenuItems = data.headerMenu;
    footerMenuItems = data.footerMenu;
    globalOptions = data.globalOptions;
    path = data?.path;
  }

  const DefaultSeoData: DefaultSeoDataType = {
    title: `${
      seo?.seo_title ??
      "EZ Digital - " + (pageContext?.data?.title ?? "Not Found")
    }`,
    description: seo?.meta_description,
    language: `en`,
    nofollow: seo?.no_follow === "true",
    no_index: seo?.no_index === "true",
    canonical:
      pageContext.data?.title === "Blog"
        ? process.env.GATSBY_FRONTEND_URL! + "/blog"
        : path
        ? process.env.GATSBY_FRONTEND_URL! + "/404"
        : process.env.GATSBY_FRONTEND_URL! +
          (path.includes("?")
            ? path.toLowerCase().trim().substring(0, path.trim().indexOf("?"))
            : path.toLowerCase().trim()),
    openGraph: {
      url:
        pageContext.data?.title === "Blog"
          ? process.env.GATSBY_FRONTEND_URL! + "/blog"
          : path
          ? path
          : "/404",
      title:
        seo?.facebook_title ??
        seo?.seo_title ??
        pageContext?.data?.title + " - EZ Digital",
      description: seo?.facebook_description ?? seo?.meta_description,
      images: [
        {
          url: seo?.facebook_image?.source_url,
          width: seo?.facebook_image?.media_details.width,
          height: seo?.facebook_image?.media_details.height,
          alt: seo?.facebook_image?.alt,
        },
      ],
      site_name: "EZ Digital",
    },
    twitter: {
      handle: "@ezdigitalweb",
      site: "@ezdigitalweb",
      cardType: "summary_large_image",
    },
  };

  return (
    <>
      <div style={{ height: "100%", paddingTop: "100px" }}>
        <Helmet encodeSpecialCharacters={false}>
          <meta charSet="utf-8" />
          <meta
            name="viewport"
            content=" width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
          />
          <html lang="en" />
          <link
            rel="apple-touch-icon"
            sizes="180x180"
            href="/apple-touch-icon.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="32x32"
            href="/favicon-32x32.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="16x16"
            href="/favicon-16x16.png"
          />
          <link rel="manifest" href="/site.webmanifest" />
          <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#f15b20" />
          <meta name="msapplication-TileColor" content="#ffc40d" />
          <meta name="theme-color" content="#ffffff"></meta>

          <script>
            {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-KCGSRWX');`}
          </script>


<link href="https://assets.calendly.com/assets/external/widget.css" rel="stylesheet"/>
<script src="https://assets.calendly.com/assets/external/widget.js" type="text/javascript" async></script>

        </Helmet>

        <GatsbySeo {...DefaultSeoData} />

        <GlobalStyle />
        <Header
          headerMenuItems={headerMenuItems}
          globalOptions={globalOptions}
          path={path}
        />
        {children}
        <Footer
          footerMenuItems={footerMenuItems}
          globalOptions={globalOptions}
        />
      </div>

      {/* <!-- Google Tag Manager (noscript) --> */}
      <noscript
        dangerouslySetInnerHTML={{
          __html: `
            <iframe
            src="https://www.googletagmanager.com/ns.html?id=GTM-KCGSRWX"
            height="0"
            width="0"
            style="display:none;visibility:hidden"
          />`,
        }}
      />
      {/* <!-- End Google Tag Manager (noscript) --> */}
    </>
  );
};

export default Layout;
