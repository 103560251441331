import React from "react";
import { Link } from "gatsby";
import {
  GlobalOptions,
  MenuItems,
} from "../shared/interfaces/graphql.interface";
import { RespContainer, AppButtonLink } from "../styles/common";
import styled from "styled-components";
import { Colors, Devices } from "../styles/variables";
import { FontelloIcon } from "../styles/CommonComponents";

interface FooterDataProps {
  footerMenuItems: MenuItems;
  globalOptions: GlobalOptions;
}

const SocialMediaIconNames = {
  facebook: "facebook-squared",
  linkedin: "linkedin-squared",
  twitter: "twitter",
};

const Footer: React.FC<FooterDataProps> = ({
  footerMenuItems,
  globalOptions,
}) => {
  const slicedFooterMenuItems = [
    footerMenuItems?.slice(0, 3),
    footerMenuItems?.slice(3, 7),
    footerMenuItems?.slice(7, 10),
    footerMenuItems?.slice(10, 13),
  ];
  return (
    <FooterComponentWrapper>
      <RespContainerFooter>
        <FooterWrapper>
          <FooterLeftBar>
            <LogoWrapper>
              <Link to={"/"} aria-label={"go to home page"}>
                <Logo
                  src={globalOptions?.logo_white_mobile.source_url}
                  alt={globalOptions?.logo_white_mobile.alt_text}
                />
              </Link>
            </LogoWrapper>
            <SocialIconsWrapper>
              {globalOptions &&
                Object.entries(
                  globalOptions?.company_information.social_links
                ).map((sl, idx) => (
                  <LinkWrapper key={`social-${idx}`}>
                    <LinkSocial
                      href={sl[1]}
                      rel={"noopener,noreferrer"}
                      target="_blank"
                      aria-label={sl[0]}
                    >
                      <FontelloIcon icon={SocialMediaIconNames[sl[0]]} />
                    </LinkSocial>
                  </LinkWrapper>
                ))}
            </SocialIconsWrapper>
            <CompanyInfo>
              <CompanyInfoText
                href={`mailto:${globalOptions?.company_information.email}`}
              >
                {globalOptions?.company_information.email}
              </CompanyInfoText>
              <CompanyInfoText
                href={`tel:${globalOptions?.company_information.phone}`}
              >
                {globalOptions?.company_information.phone}
              </CompanyInfoText>
            </CompanyInfo>
          </FooterLeftBar>

          <FooterRightBar>
            <Heading2>{globalOptions?.footer.main_text}</Heading2>
            <FooterButton to={"/contact"}>
              {globalOptions?.footer.button_text}
            </FooterButton>
          </FooterRightBar>
        </FooterWrapper>

        <FooterExtras>
          <DivisorLine />
          <CopyRight>
            <CopyRightText>
              {globalOptions?.company_information.copyright_text}
            </CopyRightText>
          </CopyRight>
        </FooterExtras>
      </RespContainerFooter>
    </FooterComponentWrapper>
  );
};

export default Footer;

const FooterComponentWrapper = styled("div")`
  /* background-color: ${Colors.black}; */
  background-color: ${Colors.dodgeBlue2};

  a {
    color: unset;
    text-decoration: unset;
  }
`;

const RespContainerFooter = styled(RespContainer)`
  padding: 0;
  /* height: 28.75rem; */
  /* background-color: ${Colors.black}; */
  color: ${Colors.white};
`;

// footer tag
const FooterWrapper = styled("footer")`
  align-items: flex-end;
  width: 100%;
  display: flex;
  padding-top: 3rem;
  justify-content: center;

  @media all and (min-width: ${Devices.bootstrapTabletBreakpoint}) {
    justify-content: space-between;
    align-items: center;
  }
`;

const FooterRightBar = styled("div")`
  @media all and (min-width: ${Devices.bootstrapTabletBreakpoint}) {
    display: flex;
    width: 70%;
    flex-direction: column;
    align-items: center;
  }
  @media all and (min-width: ${Devices.tabletLaptopBreakpoint}) {
    width: 75%;
  }
  display: none;
`;

const MenuItemsWrapper = styled("nav")`
  display: flex;
  margin-top: 2.25rem;
  width: 100%;
`;

const MenuItemsInnerWrapper = styled("div")`
  display: flex;
  padding: 0;
  margin: 0;
  gap: 15px;
  width: 100%;
  justify-content: center;
  flex-direction: column;

  > div:not(:last-child) {
    padding-bottom: 1rem;
  }

  @media all and (min-width: ${Devices.mobileBreakpoint}) {
    flex-direction: row;
    flex-wrap: wrap;
  }

  @media all and (min-width: ${Devices.bootstrapTabletBreakpoint}) {
    > div:not(:last-child) {
      padding-bottom: unset;
      padding-right: 3rem;
    }
  }

  @media all and (min-width: ${Devices.laptopMacBreakpoint}) {
    gap: 5px;
    > div:not(:last-child) {
      padding-right: 10rem;
    }
  }
`;

const MenuItemsUnOrderedList = styled("ul")`
  display: flex;
  flex-direction: column;
  list-style-type: none;
  gap: 15px;
  padding: 0;
  margin: 0;

  @media all and (min-width: ${Devices.mobileBreakpoint}) {
    width: 50%;
  }

  @media all and (min-width: ${Devices.bootstrapTabletBreakpoint}) {
    width: 25%;
  }
  @media all and (min-width: ${Devices.tabletLaptopBreakpoint}) {
    gap: 5px;
  }
`;

const MenuItemWrapper = styled("li")`
  /* padding-right: 4.5rem; */
`;

const MenuListItem = `
  font-size: 15px;
  text-decoration: none;
  color: ${Colors.white};
  border-bottom: 1px solid transparent;

  &:hover,
  &:focus {
    outline: none;
    color: ${Colors.white} !important;
    text-decoration: none;
    border-bottom-color: ${Colors.white};
  }
`;

const FooterLeftBar = styled("div")`
  /* padding-top: 1.25rem; */
  /* width: 270px;
  max-width: 270px; */
  height: 100%;

  display: flex;
  align-items: center;
  flex-direction: column;
  @media all and (min-width: ${Devices.bootstrapTabletBreakpoint}) {
    width: 30%;
    align-items: flex-start;
  }
  @media all and (min-width: ${Devices.tabletLaptopBreakpoint}) {
    width: 25%;
  }
`;

const LogoWrapper = styled("div")`
  filter: brightness(0) invert(1);
`;
const Logo = styled("img")`
  /* height: 89px; */
  width: 270px;
`;

const SocialIconsWrapper = styled("div")`
  display: flex;
  margin-top: 2rem;
  justify-content: center;

  @media all and (min-width: ${Devices.bootstrapTabletBreakpoint}) {
    justify-content: flex-end;
  }
`;

const LinkWrapper = styled("div")`
  display: flex;

  &:not(:first-child) {
    margin-left: 2.5rem;
  }
`;

const LinkSocial = styled("a")`
  color: ${Colors.white};
  text-decoration: unset;
  span {
    padding-bottom: 2px;
    border-bottom: 1px solid transparent;
  }

  span:hover {
    color: ${Colors.ezGray};
  }

  &:focus {
    span {
      border-bottom: 1px solid ${Colors.white};
    }
    outline: none;
  }
`;

const CompanyInfo = styled("div")`
  display: flex;
  margin-top: 1.25rem;
  justify-content: space-between;

  flex-direction: column;
  align-items: center;

  @media all and (min-width: ${Devices.tabletLaptopBreakpoint}) {
    flex-direction: unset;
    align-items: unset;
  }
`;

const CompanyInfoText = styled("a")`
  font-size: 0.875rem;
  color: ${Colors.white};
  text-decoration: none;
  border-bottom: 1px solid transparent;

  &:hover,
  &:focus {
    outline: none;
    text-decoration: none;
    color: ${Colors.white};
    border-bottom-color: ${Colors.white};
  }

  @media all and (min-width: ${Devices.tabletLaptopBreakpoint}) {
    margin-right: 14px;
  }
`;

const CopyRight = styled("div")`
  margin: 1rem 0;
  text-align: center;
`;

const CopyRightText = styled("span")`
  font-size: 0.875rem;
`;

const Heading2 = styled("h2")`
  font-weight: 600;
  line-height: 4.5rem;
  margin: 0;
  margin-bottom: 1.875rem;

  @media all and (min-width: ${Devices.bootstrapTabletBreakpoint}) {
    font-size: 2.25rem;
  }

  @media all and (min-width: ${Devices.tabletLaptopBreakpoint}) {
    font-size: 2.75rem;
  }

  @media all and (min-width: ${Devices.laptopMacBreakpoint}) {
    font-size: 3.5rem;
  }

  @media all and (min-width: ${Devices.laptopBreakpoint}) {
    font-size: 4rem;
  }
`;

const FooterButton = styled(AppButtonLink)`
  width: 24rem;
`;

const FooterExtras = styled("div")`
  text-align: center;
  width: 80%;

  display: flex;
  justify-content: center;
  /* margin-bottom: 40px; */
  flex-direction: column;
  align-items: center;

  @media all and (min-width: ${Devices.bootstrapTabletBreakpoint}) {
    width: 100%;
    text-align: none;
  }
`;

const DivisorLine = styled("div")`
  margin-top: 3rem;
  height: 2px;
  width: 100%;
  /* background: ${Colors.sharpBlue}; */
  background: ${Colors.white};
`;

const FooterMenusTitle = styled("h2")`
  display: block;
  width: 100%;
  font-size: 18px;
  font-weight: 500;
  margin-top: 1rem;
  margin-bottom: 0;

  @media all and (min-width: ${Devices.bootstrapTabletBreakpoint}) {
    font-size: 22px;
  }
`;
