import React from "react";
import { CSSProperties } from "styled-components";
// import "../fonts/css/fontello.css";

const FontelloStyles = {
  fontSize: "20px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

interface FontelloIconProps {
  icon: string;
  onClick?: () => void;
  style?: CSSProperties;
}

export const FontelloIcon = ({
  icon,
  onClick,
  style,
  ...props
}: FontelloIconProps) => (
  <span
    className={`icon-${icon}`}
    style={{ ...FontelloStyles, ...style }}
    onClick={onClick || (() => {})}
    {...props}
  />
);
