import styled from "styled-components";
import { Button } from "reactstrap";
import { Colors, Devices } from "../styles/variables";

type ContainerProps = {
  active: boolean;
};

export const Container = styled("div")`
  background: ${(props: ContainerProps) =>
    props.active ? Colors.primaryColor : Colors.secondaryColor};
`;

export const StyledButton = styled(Button)`
  background: purple;
  color: white;
`;
