import styled from "styled-components";
import { Colors, Devices } from "./variables";
import { css } from "styled-components";
import Link from "gatsby-link";

type AppButtonTypes = {
  reversedTheme?: boolean;
};

export const RespContainer = styled("div")`
  * {
    box-sizing: border-box;
  }
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: "Poppins";
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
  margin: 0;
  min-width: 320px;
  margin-left: auto;
  margin-right: auto;

  /* > div {
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
  } */

  width: 100%;

  /* <= 576px; */
  @media all and (max-width: ${Devices.bootstrapMobileBreakpoint}) {
    max-width: 375px;
  }

  /* <= 576px; */
  @media all and (min-width: ${Devices.bootstrapMobileBreakpoint}) {
    max-width: 540px;
  }

  /* <= 768px; */
  @media all and (min-width: ${Devices.mobileBreakpoint}) {
    max-width: 736px;
  }

  /* <= 992px; */
  @media all and (min-width: ${Devices.bootstrapTabletBreakpoint}) {
    max-width: 960px;
  }

  /* >= 1200px; */
  @media all and (min-width: ${Devices.tabletLaptopBreakpoint}) {
    max-width: 1140px;
  }

  /* >= 1440px; */
  @media all and (min-width: ${Devices.laptopMacBreakpoint}) {
    max-width: 1300px;
  }


`;

export const RespContainerWide = styled("div")`
  * {
    box-sizing: border-box;
  }
  font-family: "Poppins";
  display: flex;
  justify-content: center;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  margin: 0;

  > div {
    padding-left: 15px;
    padding-right: 15px;
    flex-grow: 1;
  }

  /* <= 992px; */
  @media all and (max-width: ${Devices.bootstrapTabletBreakpoint}) {
    padding-left: 0;
    padding-right: 0;
  }

  /* >= 1200px; */
  @media all and (min-width: ${Devices.tabletLaptopBreakpoint}) {
    padding-left: 7.5%;
    padding-right: 7.5%;
  }

  /* >= 1440px; */
  @media all and (min-width: ${Devices.laptopMacBreakpoint}) {
    > div {
      max-width: 1600px !important;
    }
  }
`;

const sharedButtonBottomBorderStyles = css`
  position: relative;

  &:focus {
    outline: none;
    border-color: ${Colors.ezBlue};
    box-shadow: 0 0 0 4px rgba(210,210,210,0.8);
  }
`;

const sharedButtonBottomBorderDefaultTheme = css`
  ${sharedButtonBottomBorderStyles}

  &::after {
    background: ${Colors.ezBlue};
  }

  &:focus {
    background-color: ${Colors.white};
    color: ${Colors.ezBlue};
  }
`;

const sharedButtonBottomBorderReversedTheme = css`
  ${sharedButtonBottomBorderStyles}

  &:focus {
    background: ${Colors.ezBlue};
    color: ${Colors.white};
  }

  &::after {
    background: ${Colors.white};
  }
`;

export const sharedButtonStyle = css`
  font-family: "Poppins";
  font-size: 1.125rem;
  font-weight: 500;
  letter-spacing: 0.025rem;
  text-align: center;

  border: unset;
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-radius: 4px;
  border: 2px solid transparent;

  &:hover {
    cursor: pointer;
    text-decoration: none;
  }
`;


export const sharedButtonDefaultTheme = css`
  color: ${Colors.white};
  background-color: ${Colors.sharpBlue};

  &:hover {
    background-color: ${Colors.white};
    color: ${Colors.sharpBlue};
    outline: none;
    border: 2px solid ${Colors.sharpBlue};
  }

  ${sharedButtonBottomBorderDefaultTheme}
`;

export const sharedButtonReversedTheme = css`
  color: ${Colors.sharpBlue};
  background-color: ${Colors.white};
  border-color: ${Colors.sharpBlue};

  &:hover {
    background-color: ${Colors.sharpBlue};
    color: ${Colors.white};
    outline: none;
    border: 2px solid ${Colors.sharpBlue};
  }

  ${sharedButtonBottomBorderReversedTheme}
`;

export const AppButton = styled("button")<AppButtonTypes>`
  ${sharedButtonStyle}

  ${(props) =>
    props.reversedTheme ? sharedButtonReversedTheme : sharedButtonDefaultTheme};
`;

export const AppButtonAnchor = styled("a")<AppButtonTypes>`
  // fix reboot
  line-height: unset;

  ${sharedButtonStyle}

  ${(props) =>
    props.reversedTheme ? sharedButtonReversedTheme : sharedButtonDefaultTheme};
`;

export const AppButtonLink = styled(Link)<AppButtonTypes>`
  ${sharedButtonStyle}

  ${(props) =>
    props.reversedTheme ? sharedButtonReversedTheme : sharedButtonDefaultTheme};
`;

export const EzDivisor = styled("div")<{
  alwaysBlue?: boolean;
  alwaysSmall?: boolean;
  fullWidth?: boolean;
}>`
  position: relative;
  height: 3px;
  ${(props) =>
    props.fullWidth
      ? css`
          width: 100%;
        `
      : ""}

  /* width: 25%; */
  &:after {
    content: "";
    position: absolute;
    height: 3px;
    top: 0;
    left: 0;
    width: 80px;
    background-color: ${(props) =>
      props.alwaysBlue ? Colors.sharpBlue : Colors.white};

    @media all and (min-width: ${Devices.tabletLaptopBreakpoint}) {
      background-color: ${Colors.sharpBlue};

      flex-basis: ${(props) => (props.alwaysSmall ? "80px" : "120px")};
      width: ${(props) => (props.alwaysSmall ? "80px" : "120px")};
    }
  }
`;

export const DotsBackground = styled("div")`
  background: radial-gradient(
      circle at center,
      ${Colors.gray3} 20%,
      transparent 50%
    ),
    transparent;
  background-size: 10px 10px;
  height: 250px;
  width: 250px;
`;
