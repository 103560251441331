type DevicesType = {
  //   [key: string]: string;
  bootstrapMobileBreakpoint: string;
  mobileBreakpoint: string;
  bootstrapTabletBreakpoint: string;
  tabletBreakpoint: string;
  tabletLaptopBreakpoint: string;
  macbookMiniBreakpoint: string;
  laptopMacBreakpoint: string;
  laptopBreakpoint: string;
  imacBreakpoint: string;
};

type ColorsType = {
  primaryColor: string;
  secondaryColor: string;
  tertiaryColor: string;
  white: string;
  white2: string;
  black: string;
  ezGray: string;
  ezGrayHover: string;
  ezBlackHover: string;
  ezBlackHalf: string;
  solidGray: string;
  ezBlack: string;
  ezBlue: string;
  ezBlue2: string;
  ezBlue3: string;
  sharpBlue: string;
  dodgeBlue: string;
  dodgeBlue2: string;
  gray1: string;
  gray2: string;
  gray3: string;
  gray3_07: string;
  gray4: string;
  gray5: string;
  gray6: string;
  gray7: string;
  gray8: string;
  red: string;
};

export const Devices: DevicesType = {
  bootstrapMobileBreakpoint: "576px",
  mobileBreakpoint: "768px",
  bootstrapTabletBreakpoint: "992px",
  tabletBreakpoint: "1024px",
  tabletLaptopBreakpoint: "1200px",
  macbookMiniBreakpoint: "1280px",
  laptopMacBreakpoint: "1440px",
  laptopBreakpoint: "1800px",
  imacBreakpoint: "1920px",
};

export const Colors: ColorsType = {
  primaryColor: "#093a5b",
  secondaryColor: "#175773",
  tertiaryColor: "#fbd737",
  white: "#fff",
  white2: "#eeeeee",
  black: "#000",
  ezBlack: "#222222",
  ezBlackHover: "#22222233",
  ezBlackHalf: "rgba(34, 34, 34, 0.5)",
  ezGray: "#999999",
  ezGrayHover: "#99999955",
  solidGray: "#666666",
  ezBlue: "#0421d7",
  ezBlue2: "#0419A7",
  ezBlue3: "#0038E3",
  sharpBlue: "#0C26D6",
  dodgeBlue: "#00062C",
  dodgeBlue2: "#0a1931",
  gray1: "#fbfbff",
  gray2: "#e9e9e9",
  gray3: "#D8D8D8",
  gray3_07: "rgb(216, 216, 216, 0.7)",
  gray4: "#EFEFEF",
  gray5: "#A3A3A3",
  gray6: "#B6B6B6",
  gray7: "#edeef5",
  gray8: "#f8f8fb",
  red: "#FF0000",
};
